import React from "react"
import { Heading } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"

export const AnimeHeading: React.FC<any> = ({
  text,
  fontWeight,
  fontSize,
  fontFamily,
  mb,
}) => {
  const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 50 },
  }
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible")
    } else {
      control.start("hidden")
    }
  }, [control, inView])

  return (
    <Heading
      as={motion.h2}
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontFamily={fontFamily}
      mb={mb || 8}
      variants={boxVariant}
      ref={ref}
      initial={`hidden`}
      animate={control}
    >
      {text}
    </Heading>
  )
}
