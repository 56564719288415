import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Box, Stack, Text } from "@chakra-ui/react"
import SubpageParallaxImageHero from "../components/partials/subpage-parallax-image-hero"
import { AnimeHeading } from "../components/anime-heading"
import { AnimeImage } from "../components/anime-image"
import { AnimeText } from "../components/anime-text"

interface Props {
  data: {
    allContentfulSiteImages: {
      edges: {
        node: {
          image: {
            url: string
          }
        }
      }[]
    }
    allContentfulCompanyProfile: {
      edges: any[]
    }
  }
}

const AboutUs: React.FC<Props> = ({ data }) => {
  const url = data.allContentfulSiteImages.edges[2].node.image?.url
  const ocean = data.allContentfulSiteImages.edges[0].node.image?.url
  const forest = data.allContentfulSiteImages.edges[3].node.image?.url
  const natural = data.allContentfulSiteImages.edges[1].node.image?.url

  return (
    <Layout>
      <Seo title="私たちについて" description="私たちについて" image={url} />
      <SubpageParallaxImageHero
        url={url}
        caption={`私たちについて`}
        fontFamily="serif"
      />
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack
          pt={{ base: 8, lg: 20 }}
          spacing={4}
          justify="center"
          align="center"
        >
          <Text
            fontFamily="serif"
            w={{ base: "100%", lg: `550px` }}
            textAlign="center"
            lineHeight={{ base: 2.4, lg: 2.6 }}
            p={{ base: 4, lg: 0 }}
            fontSize={{ base: 12, lg: 14 }}
          >
            私たちサイファームは、
            <Box as={`br`} />
            伊豆半島の最南端、南伊豆高原に拠点を置く
            <Box as={`br`} />
            不動産会社です。
            <Box as={`br`} />
            <Box as={`br`} />
            25年前に自然の中で暮らしたいという希望を抱き、
            <Box as={`br`} />
            手付かずの原生林が残る伊豆に移住してきました。
            <Box as={`br`} />
            <Box as={`br`} />
            移住にあたり他にも山梨県や千葉県、
            <Box as={`br`} />
            岡山県などを候補地として現地に訪れましたが
            <Box as={`br`} />
            実際に宿泊等の経験を通じて比べ物にならない程の
            <Box as={`br`} />
            自然の豊かさを感じ、
            <Box as={`br`} />
            伊豆を最終的な移住先として選びました。
            <Box as={`br`} />
            <Box as={`br`} />
            <Box as={`br`} />
            <Box as={`br`} />
            <AnimeHeading
              text={`伊豆の魅力`}
              fontWeight={`normal`}
              fontSize={{ base: 20, lg: 20 }}
              fontFamily={`serif`}
              mb={0}
            />
            <Box
              borderBottom={`1px`}
              borderColor={`blackAlpha.300`}
              w={{ base: "100%", lg: `100%` }}
            />
          </Text>
        </Stack>
      </Container>

      <Stack
        bg={`white`}
        py={{ base: 8, lg: 20 }}
        px={{ base: 0, lg: 20 }}
        align={`center`}
      >
        <Container maxW={`container.xl`}>
          <Stack
            position={`relative`}
            h={{ base: "400px", lg: `450px` }}
            w={{ base: "100%", lg: `70%` }}
            p={0}
            spacing={0}
          >
            <AnimeImage
              display={{ base: "none", lg: "block" }}
              image={ocean}
              high={`450px`}
              width={`100%`}
              top={0}
              left={0}
            />
            <AnimeImage
              display={{ base: "block", lg: "none" }}
              image={ocean}
              high={`400px`}
              width={`100%`}
              top={0}
              sm
            />
            <Stack
              bg={`linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 253, 0.8))`}
              position="absolute"
              bottom={{ base: 0, lg: -8 }}
              right={{ base: 0, lg: -12 }}
              w={{ base: "100%", lg: `500px` }}
              minH={{ base: "200px", lg: `250px` }}
              py={{ base: 4, lg: 12 }}
              px={{ base: 6, lg: 12 }}
              zIndex={75}
              spacing={6}
              align={`start`}
            >
              <AnimeText
                text={`ガレージからサーフボードを持って
                時間を忘れて波を待つ……`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`目の前に広がる
                澄み切った`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`「海」`}
                fontSize={{ base: 14, lg: 18 }}
                fontFamily={`serif`}
              />
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <Stack
        bg={`white`}
        py={{ base: 8, lg: 20 }}
        px={{ base: 0, lg: 20 }}
        align={`center`}
      >
        <Container maxW={`container.xl`}>
          <Stack
            position={`relative`}
            h={{ base: "400px", lg: `450px` }}
            w={{ base: "100%", lg: `70%` }}
            p={0}
            spacing={0}
          >
            <AnimeImage
              display={{ base: "none", lg: "block" }}
              image={forest}
              high={`450px`}
              width={`100%`}
              top={0}
              left={500}
            />
            <AnimeImage
              display={{ base: "block", lg: "none" }}
              image={forest}
              high={{ base: "400px", lg: `450px` }}
              width={`100%`}
              top={0}
              left={0}
              sm
            />
            <Stack
              bg={`linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 253, 0.8))`}
              position="absolute"
              bottom={{ base: 0, lg: -8 }}
              right={{ base: 0, lg: -12 }}
              w={{ base: "100%", lg: `500px` }}
              minH={{ base: "200px", lg: `250px` }}
              py={{ base: 4, lg: 12 }}
              px={{ base: 6, lg: 12 }}
              zIndex={75}
              spacing={6}
              align={`start`}
            >
              <AnimeText
                text={`アスファルトから離れ
                迷った先にあるのは
                手付かずの`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`「原生林」`}
                fontSize={{ base: 14, lg: 18 }}
                fontFamily={`serif`}
              />
              <AnimeText
                text={`早朝には野鳥が囀り
                雨上がりには霧が立ち込め
                幻想的な光景に包まれます`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <Stack
        bg={`white`}
        py={{ base: 8, lg: 20 }}
        px={{ base: 0, lg: 20 }}
        align={`center`}
        justify={`center`}
      >
        <Container maxW={`container.xl`}>
          <Stack
            position={`relative`}
            h={{ base: "400px", lg: `450px` }}
            w={{ base: "100%", lg: `70%` }}
            p={0}
            spacing={0}
          >
            <AnimeImage
              display={{ base: "none", lg: "block" }}
              image={natural}
              high={`450px`}
              width={`100%`}
              top={0}
              right={0}
            />
            <AnimeImage
              display={{ base: "block", lg: "none" }}
              image={natural}
              high={{ base: "400px", lg: `450px` }}
              width={`100%`}
              top={0}
              left={0}
              sm
            />
            <Stack
              bg={`linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 253, 0.8))`}
              position="absolute"
              bottom={{ base: 0, lg: -8 }}
              right={{ base: 0, lg: -12 }}
              w={{ base: "100%", lg: `500px` }}
              minH={{ base: "250px", lg: `250px` }}
              py={{ base: 4, lg: 12 }}
              px={{ base: 6, lg: 12 }}
              zIndex={75}
              spacing={6}
              align={`start`}
            >
              <AnimeText
                text={`「緑」`}
                fontSize={{ base: 13, lg: 18 }}
                fontFamily={`serif`}
              />
              <AnimeText
                text={`豊かな環境`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`まさに" 自然 "の宝庫……`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
              <AnimeText
                text={`数多くの野鳥や野草が生息する野山や
                鮎が棲まう河川、海亀が産卵に訪れる海岸など
                穢れのない大自然が一番の魅力といえます`}
                fontSize={{ base: 12, lg: 13 }}
                fontFamily={`inter`}
              />
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack
          pb={{ base: 8, lg: 8 }}
          pt={{ base: 4, lg: 20 }}
          spacing={8}
          justify="center"
          align="center"
        >
          <Text
            fontFamily="serif"
            w={{ base: "100%", lg: `550px` }}
            textAlign="center"
            lineHeight={{ base: 2.4, lg: 2.6 }}
            p={{ base: 8, lg: 0 }}
            fontSize={{ base: 12, lg: 14 }}
          >
            田舎暮らしを考えている方に対して、同じ価値観を共有し
            <Box as={`br`} display={{ base: "none", lg: "block" }} />
            自然の中で生活する事においてのアドバイスや
            <Box as={`br`} />
            きめ細かなサポートを行ってまいります。
            <Box as={`br`} />
            <Box as={`br`} />
            実際に移住してからは、
            <Box as={`br`} />
            初めてのことばかりで分からない方がほとんどです。
            <Box as={`br`} />
            <Box as={`br`} />
            先に移住してきた経験を通じて、
            <Box as={`br`} />
            また私達が提供させて頂いた
            <Box as={`br`} />
            お客様方の色々な経験などを踏まえて
            <Box as={`br`} />
            自らが田舎暮らしに憧れて移住してきたからこそわかる、
            <Box as={`br`} display={{ base: "none", lg: "block" }} />
            同じ目線での田舎暮らしにおける不安や
            <Box as={`br`} />
            問題に対処していくことを心掛けております。
            <Box as={`br`} />
            <Box as={`br`} />
            また建築等では、
            <Box as={`br`} />
            地元の工務店及び色々な建築方法などのサポートを通し、
            <Box as={`br`} display={{ base: "none", lg: "block" }} />
            インフラの整備や不安のない生活を送れるよう手助けしてまいります。
            <Box as={`br`} />
            <Box as={`br`} />
          </Text>
        </Stack>
      </Container>
    </Layout>
  )
}

export default AboutUs

export const query = graphql`
  {
    allContentfulSiteImages(
      filter: { location: { eq: "about-us" } }
      sort: { order: ASC, fields: imageNo }
      limit: 4
    ) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
    allContentfulCompanyProfile {
      edges {
        node {
          name
          address {
            address
          }
          tel
          email
          capital
          representativeName
          establishedOn
          license
          community
          workingHour
          holidays
        }
      }
    }
  }
`
