import React from "react"
import { Box } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"

export const AnimeImage: React.FC<any> = ({
  image,
  high,
  width,
  top,
  left,
  right,
  sm,
  display,
  position,
}) => {
  const boxVariant = {
    visible: {
      width: width,
      ...(sm ? { opacity: 1 } : { x: 0 }),
      transition: { duration: 1 },
    },
    hidden: { width: 0, ...(sm ? { opacity: 0 } : { x: -100 }) },
  }
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible")
    } else {
      control.start("hidden")
    }
  }, [control, inView])

  return (
    <Box
      as={motion.img}
      variants={boxVariant}
      ref={ref}
      src={image}
      initial={`hidden`}
      animate={control}
      h={high}
      {...(display ? { display } : {})}
      objectFit={`cover`}
      position={position || `absolute`}
      top={top}
      left={left}
      right={right}
    ></Box>
  )
}
